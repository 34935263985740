import React from 'react';
import Container from 'components/shared/Container';
import SectionHeader from 'components/shared/SectionHeader';
import PlacesCard from './PlacesCard';
import { PlacesContainer } from './PlacesSection.styled';

import photo_card1 from 'assets/images/photo6.png';
import photo_card2 from 'assets/images/photo7.png';
import photo_card3 from 'assets/images/photo8.png';

const PlacesSection = () => {
   const sectionText = [
      "Sprawdzamy się w różnych miejscach, ",
      <strong>od małych gabinetów, po średniej wielkości poradnie</strong>,
   ];

   const places = [
      {
         image: photo_card1,
         name: 'Poradnie zdrowotne',
      },
      {
         image: photo_card2,
         name: 'Gabinety lekarskie',
      },
      {
         image: photo_card3,
         name: 'Działy jakości',
      },
      {
         image: photo_card1,
         name: 'Działy jakości',
      },
   ];

   const placesList = places.map((place, index) => (
      <PlacesCard
         key={index}
         image={place.image}
         name={place.name}/>
   ))

   return (
      <>
         <SectionHeader sectionText={sectionText} />

         <Container>
            <PlacesContainer>
               {placesList}
            </PlacesContainer>
         </Container>
      </>
   )
}

export default PlacesSection;
