import CapabilitiesSection from 'components/shared/CapabilitiesSection';
import FeatureSection from 'components/shared/FeatureSection';
import FunctionalitiesSection from 'components/shared/FunctionalitiesSection';
import HeaderButtons from 'components/shared/HeaderButtons';
import ImplementationSection from 'components/shared/ImplementationSection';
import PackagesSection from 'components/shared/PackagesSection';
import PageHeader from 'components/shared/PageHeader';
import QuestionsSection from 'components/shared/QuestionsSection';
import SEO from 'components/shared/SEO';
import homepageData from 'data/homepage';
import React from 'react';
import Button from "../components/shared/Button";
import {SectionHeader} from "../components/Layout/Footer/Footer.styled";
import PlacesSection from "../components/pages/home/PlacesSection";

export default function Home() {
   const packages = {
      buttons: [
         {
            text: 'Wybierz Standard',
            to: '/contact',
            state: { package: 'Standard' },
         },
         {
            text: 'Wybierz Standard Plus',
            to: '/contact',
            state: { package: 'Standard Plus' },
         },
      ],
   };
   return (
      <>
         <SEO title="Strona główna" />
         <PageHeader as="header" headerText={homepageData.header}>
            <HeaderButtons />
         </PageHeader>

         <FeatureSection cards={homepageData.featureCards} />

         <PageHeader headerText={homepageData.questionTitle}>
             <Button filled={true}>Dowiedz się więcej</Button>
         </PageHeader>

         <CapabilitiesSection cards={homepageData.capabilitiesCards} />

         <FunctionalitiesSection
            title={homepageData.functionalitiesTitle}
            tabs={homepageData.functionalitiesTabs}
         />

         <PlacesSection />
         <PackagesSection packagesLinks={packages} />
      </>
   );
}
