import React from 'react';
import { colors } from 'variables';

import tile1 from 'assets/images/homepage/photo1.jpg';
import tile1x2 from 'assets/images/homepage/photo1@2x.jpg';
import tile2 from 'assets/images/homepage/photo2.jpg';
import tile2x2 from 'assets/images/homepage/photo2@2x.jpg';
import tile3 from 'assets/images/homepage/photo3.jpg';
import tile3x2 from 'assets/images/homepage/photo3@2x.jpg';

import questionIllustration from 'assets/images/homepage/illustration.svg';

import capabilities_image1 from 'assets/images/homepage/photo4.jpg';
import capabilities_image1x2 from 'assets/images/homepage/photo4@2x.jpg';
import capabilities_image2 from 'assets/images/homepage/photo5.jpg';
import capabilities_image2x2 from 'assets/images/homepage/photo5@2x.jpg';

import tab1 from 'assets/ui/app_mockup.png';
import tab1x2 from 'assets/ui/app_mockup.png';
import tab2 from 'assets/ui/app_mockup.png';
import tab2x2 from 'assets/ui/app_mockup.png';
import tab3 from 'assets/ui/app_mockup.png';
import tab3x2 from 'assets/ui/app_mockup.png';
import tab4 from 'assets/images/homepage/grafika4.png';
import tab4x2 from 'assets/images/homepage/grafika4@2x.png';
import tab5 from 'assets/ui/Mockup.png';
import tab5x2 from 'assets/ui/Mockup@2x.png';
import routes from 'data/globals/routes';

export default {
   header: [
      'System online do zarządzania ',
      <strong>placówkami medycznymi</strong>,
      <br />,
   ],
   featureCards: [
      {
         image: tile1,
         imageX2: tile1x2,
         color: colors.lightCyanMap.get(300),
         headerText: [
            <strong>System rezerwacji</strong>,
            ' wizyt lekarskich'
         ],
         description:
            'System ułatwia zarządzanie kalendarzem wizyt lekarskich poprzez tworzenie formularzy przyjęć oraz śledzenie statusu każdej wizyty.',
         buttonLink: routes.production,
      },
      {
         image: tile2,
         imageX2: tile2x2,
         imagePosition: 'right',
         color: colors.lightCyanMap.get(100),
         headerText: [
            <strong>Szybka diagnostyka</strong>,
            ' lekarska i predykcja',
         ],
         description:
            'Dzięki sztucznej inteligencji, szybciej postawisz diagnozę pacjentowi. AI wspiera system poprzez odrzucanie części możliwości wystąpienia u pacjenta chorób.',
         buttonLink: routes.laboratory,
      },
      {
         image: tile3,
         imageX2: tile3x2,
         color: colors.lightCyanMap.get(400),
         headerText: [
            'Dostosuj system do ',
            <strong>własnych potrzeb</strong>,
         ],
         buttonLink: routes.construction,
         description:
            'Łatwo dostosujesz system na potrzeby własnej kliniki. Jesteśmy również otwarci na sugestie odnośnie wprowadzania nowych funkcjonalności.',
      },
   ],

   questionTitle: [
      <strong>Proces Control</strong>,
      ' pomoże ci zarządzać każdym obszarem twojej praktyki lub kliniki z różnych lokalizacji.',
   ],
   questionIllustration: questionIllustration,
   questionClouds: [
      {
         question: [
            'Czy przygotowanie sprawozdania dla klienta ',
            <strong>zajmuje Ci za dużo czasu?</strong>,
         ],
         questionLink: 'sprawozdania',
      },
      {
         question: [
            'Chciałbyś mieć',
            <strong> większy wpływ na planowanie </strong>,
            'pracy w firmie?',
         ],
         questionLink: 'harmonogramy',
      },
      {
         question: [
            <strong>Zdarzyło Ci się wycofanie sporej partii towaru</strong>,
            ' z powodu błędu na produkcji, który trafił do Ciebie zbyt późno?',
         ],
         questionLink: 'bledy',
      },
   ],
   capabilitiesCards: [
      {
         sectionId: 'harmonogramy',
         image: capabilities_image1,
         imageX2: capabilities_image1x2,
         height: 300,
         imgAlignment: 'flex-end',
         color: colors.lightCyanMap.get(200),
         headerText: [
            'Dostosuj nasz system ',
            <strong>do własnych potrzeb</strong>,
         ],
         description:
            'Proces Control łączy w sobie cechy rowiązania pisanego na indywidualne zamówienia klienta ' +
             'z "gotowymi systemami".\n',
         buttonText: 'Czytaj dalej',
         buttonTo: {
            link: routes.functionalities,
            section: 'functionalities-reception',
         },
         reverseRow: false,
      },
      {
         sectionId: 'bledy',
         image: capabilities_image2,
         imageX2: capabilities_image2x2,
         height: 297,
         imgAlignment: 'flex-end',
         color: colors.lightCyanMap.get(100),
         headerText: [
            'Sztuczna inteligencja ',
            <strong>w służbie zdrowia pacjenta</strong>,

         ],
         description:
            'Szybsza diagnostyka lekarska dzięki wsparciu przez sztuczną inteligencję.',
         buttonText: 'Czytaj dalej',
         buttonTo: {
            link: routes.functionalities,
            section: 'functionalities-operations',
         },
         reverseRow: true,
      },

   ],
   functionalitiesTitle: ['Główne ', <strong>funkcjonalności</strong>],
   functionalitiesTabs: [
      {
         title: 'Historia pacjenta',
         text: {
            bold: '',
            normal:
               'System umożliwia szybki podgląd historii leczenia pacjenta, co w dużej mierze jest ułatwione przez formularze zgłoszeniowe. Sprawiają one, że w dokumentacji panuje ład i porządek.',
         },
         image: tab1,
         imageX2: tab1x2,
      },
      {
         title: 'Zarządzenia zasobami',
         text: {
            bold: '',
            normal:
               'System może również pełnić rolę wspomagającą zarządzanie zasobami. Dzięki czemu jest jasno określone jaki sprzęt jest w posiadaniu placówki oraz kto aktualnie go wykorzystuje.',
         },
         image: tab2,
         imageX2: tab2x2,
      },
      {
         title: 'Zarządzenia personelem',
         text: {
            bold: '',
            normal:
               'W jednym miejscu zgromadzone są zarówno dane pacjentów, jak również dane personelu placówki. Struktura organizacyjna wskazuje kto jest odpowiedzialny za dany obszar i pozwala w łatwy sposób zarządzać zasobami ludzkimi.',
         },
         image: tab3,
         imageX2: tab3x2,
      },

      {
         title: 'Tworzenie szablonów',
         text: {
            normal:
               'Dopasuj cały system do swojej firmy, a nie firmę do systemu. Stwórz własne szablony zadań, dokumentów, stanów magazynowych.',
            bold: '',
         },
         image: tab5,
         imageX2: tab5x2,
      },
   ],
   implementationTitle: [
      'Poznaj historię budowy produktu',
      <br />,
      'w ',
      <strong>KPT ScaleUp</strong>,
   ],
};
