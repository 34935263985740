import React from 'react';
import { ImageContainer, Image, ImageCaption } from './PlacesCard.styled';

const PlacesCard = ({ image, name }) => {
   return (
      <ImageContainer>
         <Image src={image} />

         <ImageCaption>{name}</ImageCaption>
      </ImageContainer>
   )
}

export default PlacesCard;
