import styled from 'styled-components';
import { breakpoints } from 'variables';

export const ImageContainer = styled.figure`
   display: flex;
   flex-direction: column;
   margin: 0;
   margin-top: 55px;
   align-items: center;
`;

export const Image = styled.img`
   width: 150px;

   ${breakpoints.lg`
      width: 200px;
   `}
`;

export const ImageCaption = styled.figcaption`
   margin-top: 30px;
   font-size: 1.5rem;
   font-weight: 300;
   text-align: center;

   ${breakpoints.lg`
      font-size: 2rem;
   `}
`;
