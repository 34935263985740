import styled from 'styled-components';
import { breakpoints } from 'variables';

export const PlacesContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   width: 100%;

   ${breakpoints.lg`
      margin-top: 100px;
      margin-bottom: 30px;
   `}
`;
